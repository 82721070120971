import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../media'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className,children }) => (
    <StaticQuery query={query}
     render={data => {
       // Set ImageData.
       const imageData = data.desktop.childImageSharp.fluid
       return (
      
          <BackgroundImage Tag="section"
                           className={className}
                           fluid={imageData}
                           backgroundColor={`#212121`}
          >
            {children}
          </BackgroundImage>
          
       )
     }
     }
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
${media.mobileS`
width:100%;
height:auto;
`}
${media.tablet`
`}
${media.laptop``}
${media.desktop``}
  /* width: 100%;
  height:auto; */
  background-position: top;
  background-repeat: repeat-y;
  background-size: cover;
`
export const query = graphql`
query {
  desktop: file(relativePath: { eq:  "bg/devBG.png"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 2560) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`
export default StyledBackgroundSection