import React from 'react'
import Gbi from './devBG'
import Header from '../header/header'
import Docs from './Docs'
import styled from 'styled-components'
import media from '../media'

const StyledTextContainer = styled.div`
${media.mobileS`
margin-top:100px;
`}
${media.tablet`
margin-top:0;
`}
color:white;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
padding: 10% 0;
`
const StyledTitle = styled.h1`
${media.mobileS`
font-size:1.5rem;
`}
${media.tablet`
font-size:2rem;
margin-top:100px;
`}
${media.laptop`
margin-top:0;
font-size:2.5rem;
`}
${media.desktop`
margin-top:0;
font-size:3rem;
`}

justify-content:center;
padding-bottom:5%;
margin:auto;
`
const StyledContent = styled.p`
${media.mobileS`
width:80%;
font-size:0.9rem;
text-align:justify;
`}
${media.tablet`
font-size:1.2rem;
width:62%;
`}
${media.laptop`
font-size:1.2rem;
width:62%;
`}
${media.desktop`
font-size:2rem;
width:62%;
`}
color:lightgray;
font-family:sans-serif;

text-align:center;
`

const Dev = () => {
    return (
        <Gbi>
            <Header/>
            <StyledTextContainer>             
                <StyledTitle>API Documentation</StyledTitle>
                <StyledContent>Temporal's API comes in two flavours, hosted or on-site. Should you not have the resources, or interest in maintaining your own infrastructure you can take advantage of our hosted API running in our very own datacenter. Those which have the interest, and/or resources may deploy Temporal within your own environments. For those that deploy Temporal themselves, we offer paid for support, installation, tutorials, and product usage information sessions allowing organizations to leverage all the capabilities that Temporal offers.</StyledContent>
            </StyledTextContainer>
            <Docs/>
        </Gbi>
    )
}

export default Dev
