import React from "react"
import Layout from "../components/layout"
import Dev from '../components/dev/Dev'
import MobileHeader from '../components/header/headerMobile'

const dev = () => {
  return (
  <Layout>
    <MobileHeader/>
    <Dev/>
  </Layout>
  )}

export default dev
