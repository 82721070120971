import React from 'react'
import Img from 'gatsby-image'
import {StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../media'

const StyledImagesContainer = styled.div`
${media.mobileS`
flex-direction:column;
`}
${media.tablet`
flex-direction:row;
`}
display:flex;
justify-content:space-around;
align-items:center;
padding-bottom:5%;
margin:1% 10%;
`
const StyledDoc = styled.a`
text-decoration:none;
${media.mobileS`
width:90%;
flex-direction:row;
padding:0;
justify-content:space-around;
align-items:center;
padding:1rem;
margin: 5% 0;
`}
${media.tablet`
width:25%;
flex-direction:column;
height:25vh;
padding:2rem;
`}
${media.laptop`
height:30vh;
`}
${media.laptopL`
height:35vh;
`}
background-color:#414040;
display:flex;
justify-content:center;
align-items:center;
`
const StyledDocTitle = styled.h2`
${media.mobileS`
margin-bottom:0;
font-size:1.5rem;
`}

${media.desktop`
font-size:3rem;
`}
color:white;
font-family:sans-serif;
`
const StyledImage = styled(Img)`
${media.mobileS`
width:20%;
`}
${media.tablet`
width:50%;
`}
`

const DevDocs = () => (

    <StaticQuery
      query={ImagesQ}
      render={data =>
        <div style={{paddingBottom:"5%"}}>
      
       <StyledImagesContainer >
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/ipfs.html">    
              
                <StyledDocTitle>IPFS</StyledDocTitle>
                <StyledImage fluid={data.image1.childImageSharp.fluid} />
                
            </StyledDoc>
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/ipns.html">
                <StyledDocTitle>IPNS</StyledDocTitle>
                <StyledImage fluid={data.image2.childImageSharp.fluid} />
            </StyledDoc>
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/lens.html">
                <StyledDocTitle>Lens</StyledDocTitle>
                <StyledImage fluid={data.image3.childImageSharp.fluid} />
            </StyledDoc>
            </StyledImagesContainer>     
            <StyledImagesContainer>     
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/account.html">
                <StyledDocTitle>Account</StyledDocTitle>
                <StyledImage fluid={data.image4.childImageSharp.fluid} />
            </StyledDoc>
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/database.html">
                <StyledDocTitle>Database</StyledDocTitle>
                <StyledImage fluid={data.image5.childImageSharp.fluid} />
            </StyledDoc>
            <StyledDoc href="https://gateway.temporal.cloud/ipns/docs.api.temporal.cloud/payment.html">
                <StyledDocTitle>Payment</StyledDocTitle>
                <StyledImage fluid={data.image6.childImageSharp.fluid} />
            </StyledDoc>
           </StyledImagesContainer>          
        </div>
        }
    />
  

)

export const ImagesQ = graphql`
  fragment DevDocsImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "devIcons/ipfs.png" }) {
      ...DevDocsImage
    }

    image2: file(relativePath: { eq: "devIcons/ipns.png" }) {
      ...DevDocsImage
    }
    image3: file(relativePath: { eq: "devIcons/lens.png" }) {
      ...DevDocsImage
    }
    image4: file(relativePath: { eq: "devIcons/account.png" }) {
      ...DevDocsImage
    }
    image5: file(relativePath: { eq: "devIcons/database.png" }) {
      ...DevDocsImage
    }
    image6: file(relativePath: { eq: "devIcons/payment.png" }) {
      ...DevDocsImage
    }

  }
`

export default DevDocs